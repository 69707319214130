const listDetailCtn = [
  {
    id:0,
    text: `Ne vous laissez plus déconcentrer par la résolution des problèmes d’infrastructure sans fin. 
    Restez focus sur des problèmes plus importants, à savoir votre développement.`,
    title: "Concentrez-vous sur votre entreprise",
  },
  {
    id:1,
    text: `La plupart des opérations sont d'une grande complexité et les risques sont nombreux. 
    Laissez GetCaaS diminuer votre stress grâce à l'orchestration de composants cloud complexes.`,
    title: "Automatisez vos processus complexes",
  },
]

export default listDetailCtn