
/*Import de librairies*/
import React from "react"

/*Import de fichiers en local*/
import "../../scss/components/main/StripeFour.scss"
import listDetailCtn4 from "../../utils/main/listDetailCtn4"

import plateform from "../../images/bandeau2/minimise_espace_requis.png"

function StripeNine() {

  return (

    <div className="bandeau zone4__header marged">
      <div className="zone4__header__text ">
        <span className="bigText"><span>Exactement ce qu'il vous faut.</span> Augmentation ou réduction <span>instantanée</span> pour répondre à vos besoins d'<span>évolutions rapides.</span></span>
      </div>
      <div className="zone4 marged">
        <div className="zone4__imageCtn">
          <img src={plateform} title="plateform" alt="multicloud système"/>
        </div>
        <div className="zone4__slideshowSlider" >
          <div className="zone4__slideshowSlider__ctn">
            <div>
              {
                listDetailCtn4.map((el, idx) => {
                  return (
                    <div className="p-5">
                      <div className="m-3">
                        <span className="color1">{listDetailCtn4[idx].title}</span>
                      </div>
                      <div>
                        <p>{listDetailCtn4[idx].text}</p>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default StripeNine