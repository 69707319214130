import React from "react"
import Layout from "../components/decorate/Layout"
import StripeFour from "../components/main/StripeFour"
import StripeEight from "../components/main/StripeEight"
import "../scss/pages/preSignin.scss"
import StripeNine from "../components/main/StripeNine"

function whyGetCaaS() {

  return (

    <Layout>
      <StripeFour/>
      <StripeNine />
      <StripeEight/>
    </Layout>

  )
}

export default whyGetCaaS