
/*Import de librairies*/
import React from "react"

/*Import de fichiers en local*/
import "../../scss/components/main/StripeFour.scss"
import listDetailCtn2 from "../../utils/main/listDetailCtn2"
import listDetailCtn3 from "../../utils/main/listDetailCtn3"

import multiCloud from "../../images/bandeau2/multi_cloud.png"
import plateform from "../../images/bandeau2/compatibilite_assuree.png"

function StripeFour() {

  return (

    <div className="bandeau zone4__header marged">
      <div className="zone4__header__text ">
        <span className="bigText">GetCaaS est le moyen le <span>plus simple</span> pour déployer et gérer une infrastructure <span>flexible</span> sans prise de tête</span>
      </div>
      <div className="zone4 marged">
        <div className="zone4__imageCtn">
          <img src={plateform} title="plateform" alt="multicloud système"/>
        </div>
        <div className="zone4__slideshowSlider" >
          <div className="zone4__slideshowSlider__ctn">
            <div>
              {
                listDetailCtn3.map((el, idx) => {
                  return (
                    <div className="p-5">
                      <div className="m-3">
                        <span className="color1">{listDetailCtn3[idx].title}</span>
                      </div>
                      <div>
                        <p>{listDetailCtn3[idx].text}</p>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>
      <div className="zone4 marged">
        <div className="zone4__slideshowSlider" >
          <div className="zone4__slideshowSlider__ctn ">
            <div>
              {
                listDetailCtn2.map((el, idx) => {
                  return (
                    <div className="p-5">
                      <div className="m-3">
                        <span className="color1">{listDetailCtn2[idx].title}</span>
                      </div>
                      <div>
                        <p>{listDetailCtn2[idx].text}</p>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
        <div className="zone4__imageCtn">
          <img src={multiCloud} title="minspace" alt="multicloud système"/>
        </div>
      </div>
    </div>

  )
}

export default StripeFour