const listDetailCtn = [
  {
    id:0,
    text: `Débutez à petite échelle ou propulsez votre application directement sur la Lune. GetCaaS facilite la mise à l'échelle et l'expansion de votre infrastructure afin de répondre à des charges de travail de production en temps réel et en constante évolution.`,
    title: "Aucun plafond, aucune limitation",
  },
  {
    id:1,
    text: `GetCaaS est un condensé de pratiques DevOps automatisé qui exploite une infrastructure de production à grande échelle depuis de nombreuses années.Faites-vous accompagner par GetCaaS.`,
    title: "Pas besoin d'expertise DevOps/ DevSecOps",
  },
]

export default listDetailCtn