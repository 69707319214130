const listDetailCtn = [
  {
    id:2,
    text: "Réduire l'amplitude technique et les éléments non-maitrisés permet de mieux se concentrer sur votre application. " +
      "GetCaaS vous aide à vous concentrer sur les bonnes choses.",
    title: "Réduisez votre dette technique",
  },
  {
    id:3,
    text: "GetCaaS s'appuie sur des réglages par défaut optimisés, vous n'avez qu'à paramétrer et exécuter rapidement. " +
      "Quand vous en avez besoin, GetCaaS vous permet de personnaliser de façon affiné vos réglages.",
    title: "Simple par défaut, hautement personnalisable",
  }
]

export default listDetailCtn